<template>
  <div v-loading="$h.isSubmitting() || $h.isFetching()" class="bg-white">
    <!-- Header -->
    <div
      class="front-header-cont px-0 px-md-0 py-0 py-md-0"
      :class="{
        'rel-header': !$route.meta.absHeader && mq.current != 'xsmall',
        'abs-sm-header': mq.current == 'xsmall',
      }"
    >
      <div class="fr-top-header px-4 px-md-6 d-flex justify-content-end">
        <div class="d-flex align-items-center justify-content-between">
          <p class="mb-0 mr-2 c-pointer" @click="$r.changeRoute('/contact')">
            {{ $t.translate("FP_CONTACT_US") }}
          </p>

          <div class="d-flex pr-0">
            <div
              v-for="item in TextSizesList"
              :key="item.value"
              class="d-flex text-size-cont align-items-end"
            >
              <img
                class="ml-1 c-pointer"
                :src="
                  item.value == currentTextSize
                    ? '/img/front/text-active.png'
                    : '/img/front/text-inactive.png'
                "
                :class="item.class"
                @click="changeTextSize(item.value)"
                alt="text"
              />
            </div>
          </div>

          <img
            src="/img/front/earth.png"
            style="height: 14px"
            class="ml-3 mr-2"
            alt="..."
          />
          <div class="d-flex pr-0">
            <div v-for="(lang, ind) in LangList" :key="lang.code" class="d-flex">
              <div v-if="ind != 0" class="mx-1">|</div>
              <p class="mb-0 c-pointer" @click="changeLang(lang.code)">
                {{ $t.translate(lang.name) }}
              </p>
            </div>
          </div>

          <img
            src="/img/front/user.png"
            style="height: 14px"
            class="ml-3 ml-md-4 mr-2"
            alt="..."
          />
          <p v-if="User" class="mb-0 c-pointer" @click="clearUserData">
            {{ $t.translate("FP_LOGIN_OUT") }}
          </p>
          <p v-else class="mb-0 c-pointer" @click="$r.changeRoute('/login')">
            {{ $t.translate("FP_LOGIN_IN") }}
          </p>
        </div>
      </div>

      <div class="front-header px-4 px-md-6 pt-1 py-md-3">
        <div class="d-flex d-md-none justify-content-between mt-2">
          <div class="icon-cont c-pointer" @click="menuDrawer = true">
            <i class="fas fa-bars text-white" style="font-size: 13px"></i>
          </div>

          <div>
            <div class="icon-cont c-pointer" @click="$r.changeRoute('/disclosure')">
              <i class="fas fa-search text-white" style="font-size: 13px"></i>
            </div>
          </div>
        </div>

        <div class="d-none d-md-flex align-items-start justify-content-between">
          <div class="d-flex justify-content-center">
            <div class="c-pointer" @click="$r.changeRoute('/home')">
              <img src="/img/brand/logo.png" class="head-logo" alt="..." />
            </div>

            <div class="focal-div pl-3">
              <p class="focal-title mb-0">{{ $t.translate("FP_FOCALPOINT") }}</p>
              <p class="focal-content mb-0">
                {{ $t.translate("FP_FOCALPOINT_CONTENT") }}
              </p>
            </div>
          </div>

          <div class="d-md-flex justify-content-center">
            <el-menu
              :default-active="activeMenu"
              class="nav-menu-cont d-md-flex"
              mode="horizontal"
              @select="handleSelect"
            >
              <template v-for="(item, index) in Links" :key="item.path">
                <el-submenu
                  v-if="item?.children?.length"
                  :index="item.path"
                  popper-class="front-popper"
                >
                  <template #title
                    ><span @click="showParent(item)">{{
                      $t.translate(item.name)
                    }}</span></template
                  >
                  <el-menu-item
                    v-for="subItem in item.children"
                    :key="subItem.path"
                    :index="subItem.path"
                  >
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="md-pop-submenu">
                        <span class="mx-2">-</span>{{ $t.translate(subItem.name) }}
                      </div>
                      <i class="el-icon-arrow-right fr-sm-menu-arrow"></i>
                    </div>
                  </el-menu-item>
                </el-submenu>

                <el-menu-item
                  v-else
                  :index="item.path"
                  :disabled="item.disabled ? true : false"
                  :class="{ 'pr-0': index == Links.length - 1 }"
                  >{{ $t.translate(item.name) }}</el-menu-item
                >
              </template>
            </el-menu>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      v-model="menuDrawer"
      :show-close="false"
      direction="ltr"
      custom-class="sm-menu-drawer d-flex d-md-none"
    >
      <div class="d-flex align-items-center justify-content-center mt-3 mb-4">
        <div class="c-pointer" @click="$r.changeRoute('/home')">
          <img src="/img/brand/logo.png" class="head-logo" alt="..." />
        </div>
      </div>

      <el-menu :default-active="activeMenu" class="sm-menu-cont" @select="handleSelect">
        <template v-for="item in Links" :key="item.path">
          <el-submenu
            v-if="item?.children?.length"
            :index="item.path"
            popper-class="front-popper"
          >
            <template #title
              ><div
                :class="{ 'is-active': item.path == activeMenu }"
                @click="showParent(item)"
              >
                {{ $t.translate(item.name) }}
              </div></template
            >
            <el-menu-item
              v-for="subItem in item.children"
              :key="subItem.path"
              :index="subItem.path"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="mr-2">-</span>{{ $t.translate(subItem.name) }}
                </div>
                <i class="el-icon-arrow-right fr-sm-menu-arrow"></i>
              </div>
            </el-menu-item>
          </el-submenu>

          <el-menu-item
            v-else
            :index="item.path"
            :disabled="item.disabled ? true : false"
            >{{ $t.translate(item.name) }}</el-menu-item
          >
        </template>

        <div class="mb-5">
          <el-menu-item
            v-for="item in FooterLinksContact.concat(FooterLinks)"
            :key="item.path"
            :index="item.path"
            :disabled="item.disabled ? true : false"
            >{{ $t.translate(item.name) }}</el-menu-item
          >
        </div>

        <div class="el-menu-item focus-none d-flex pr-0">
          <div v-for="(lang, ind) in LangList" :key="lang.code" class="d-flex">
            <div v-if="ind != 0" class="mx-1">|</div>
            <div class="c-pointer font-weight-normal" @click="changeLang(lang.code)">
              {{ $t.translate(lang.name) }}
            </div>
          </div>
        </div>

        <div class="el-menu-item d-flex align-items-end pr-0">
          <div class="font-size-text mr-1">
            <span>{{ $t.translate("FP_FONTSIZE", "Font Size") }}</span>
          </div>

          <div
            v-for="tItem in TextSizesList"
            :key="tItem.value"
            class="d-flex text-size-cont align-items-end"
          >
            <img
              class="ml-1 c-pointer"
              :src="
                tItem.value == currentTextSize
                  ? '/img/front/text-active-sm.png'
                  : '/img/front/text-inactive-sm.png'
              "
              :class="tItem.class"
              @click="changeTextSize(tItem.value)"
              alt="text"
            />
          </div>
        </div>
      </el-menu>
    </el-drawer>

    <!-- Page content -->
    <div>
      <router-view></router-view>
    </div>

    <footer>
      <div
        class="front-cont f-footer-bg px-3 px-md-5 py-4 align-items-center justify-content-between"
      >
        <div class="px-3 px-md-5 row align-items-center justify-content-between">
          <div class="col-md-5 align-items-center d-flex mb-3 mb-md-0">
            <div class="c-pointer" @click="$r.changeRoute('/home')">
              <img src="/img/brand/logo.png" class="head-logo" alt="logo-f" />
            </div>

            <div class="ml-3">
              <p class="copy-txt">
                {{
                  $t
                    .translate("LBL_COPYRIGHT_CONTENT")
                    .replace("{YEAR}", new Date().getFullYear())
                }}
              </p>
              <p class="copy-txt">{{ $t.translate("LBL_ALL_RIGHTS") }}</p>
            </div>
          </div>

          <el-menu
            :default-active="activeMenu"
            class="col-md-7 nav-menu-cont footer-menu-cont mb-2 d-flex d-md-none"
            mode="horizontal"
            @select="handleSelect"
          >
            <template
              v-for="item in FooterLinksContact.concat(FooterLinks)"
              :key="item.path"
            >
              <el-menu-item :index="item.path">{{
                $t.translate(item.name)
              }}</el-menu-item>
            </template>
          </el-menu>

          <div class="col-md-3 d-none d-md-block">
            <h3
              v-for="item in FooterLinks"
              :key="item.path"
              @click="handleSelect(item.path)"
              class="cus-menu-item mb-0 c-pointer"
              :class="{ 'is-active': item.path == activeMenu }"
            >
              {{ $t.translate(item.name) }}
            </h3>
          </div>
          <div class="col-md-4 d-flex align-self-start justify-content-md-end">
            <div>
              <template v-for="item in FooterLinksContact" :key="item.path">
                <h3
                  @click="handleSelect(item.path)"
                  class="cus-menu-item mb-0 c-pointer d-none d-md-block"
                  :class="{ 'is-active': item.path == activeMenu }"
                >
                  {{ $t.translate(item.name) }}
                </h3>

                <div v-if="item?.contactInfo" class="mt-2 mt-md-0">
                  <div class="d-flex align-items-center cus-contact-item">
                    <img src="/img/front/phone.png" class="contact-img mr-2" alt="" />
                    <p>{{ item.contactInfo.MobileNumber }}</p>
                  </div>
                  <div class="d-flex align-items-center cus-contact-item">
                    <img src="/img/front/address.png" class="contact-img mr-2" alt="" />
                    <p>{{ item.contactInfo.Address }}</p>
                  </div>
                  <div class="d-flex align-items-center cus-contact-item">
                    <img src="/img/front/email.png" class="contact-img mr-2" alt="" />
                    <p>
                      <a :href="'mailto:' + item.contactInfo.Email">{{
                        item.contactInfo.Email
                      }}</a>
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div
          class="d-flex justify-content-start justify-content-md-end px-3 px-md-5 pt-3 pt-md-0 pb-4 pb-md-0"
        >
          <img
            height="32"
            width="88"
            src="https://www.w3.org/WAI/wcag2AA"
            alt="WCAG-2.0-AA"
          />
        </div>
      </div>

      <!-- <div
        class="front-cont px-3 px-md-5 pb-0 pb-md-6 pt-0 pt-md-5 align-items-center justify-content-between subscribe-cont"
      >
        <div class="px-0 px-md-5 row align-items-center justify-content-between">
          <div
            class="col-md-6 align-items-center py-4 py-md-0 mx-3 mx-md-0 subscribe-card"
          >
            <h1 class="f-text-blue f-footer-title mb-2">
              {{ $t.translate("TTL_SUBSCRIBE") }}
            </h1>
            <h4 class="f-text-orange mb-2 mb-md-3">
              {{ $t.translate("LBL_SUBSCRIBE_TO_NEWS") }}
            </h4>

            <div class="d-flex align-items-center">
              <i class="fas fa-envelope mr-2" />
              <el-input v-model="formData.Email" class="black-input"></el-input>

              <el-button
                type="info"
                @click="subscribeEmail"
                class="f-btn-common f-btn-orange f-footer-sm-submit ml-2 ml-md-4"
              >
                <b>{{ $t.translate("BTN_SUBMIT") }}</b>
              </el-button>
            </div>
          </div>
          <div class="col-md-1" />
          <div class="col-md-5 justify-content-between d-none d-md-flex">
            <img
              src="/img/front/linkedin.png"
              alt="linkedin-alt"
              class="c-pointer share-img"
              @click="shareNow('linkedin')"
            />
            <img
              src="/img/front/twitter.png"
              alt="twitter-alt"
              class="c-pointer share-img"
              @click="shareNow('twitter')"
            />
            <img
              src="/img/front/fb.png"
              alt="fb-alt"
              class="c-pointer share-img"
              @click="shareNow('fb')"
            />
            <img
              src="/img/front/ytb.png"
              alt="ytb-alt"
              class="c-pointer share-img"
              @click="shareNow('ytb')"
            />
            <img
              src="/img/front/share.png"
              alt="share-alt"
              class="c-pointer share-img"
              @click="shareNow('share')"
            />
          </div>
        </div>
      </div>
      <div class="justify-content-between pt-4 pb-4 px-6 d-flex d-md-none">
        <img
          src="/img/front/linkedin.png"
          alt="linkedin-alt"
          class="c-pointer share-img"
          @click="shareNow('linkedin')"
        />
        <img
          src="/img/front/twitter.png"
          alt="twitter-alt"
          class="c-pointer share-img"
          @click="shareNow('twitter')"
        />
        <img
          src="/img/front/fb.png"
          alt="fb-alt"
          class="c-pointer share-img"
          @click="shareNow('fb')"
        />
        <img
          src="/img/front/ytb.png"
          alt="ytb-alt"
          class="c-pointer share-img"
          @click="shareNow('ytb')"
        />
        <img
          src="/img/front/share.png"
          alt="share-alt"
          class="c-pointer share-img"
          @click="shareNow('share')"
        />
      </div> -->
    </footer>
  </div>
</template>
<script>
import {
  onBeforeMount,
  onMounted,
  onUpdated,
  ref,
  reactive,
  computed,
  watchEffect,
} from "vue";
import { translation } from "@/utils/translation";
import { store } from "@/store";
import { router } from "@/utils/router";
import { useRoute } from "vue-router";
import { useMq } from "vue3-mq";
import _ from "lodash";
import { get } from "@/utils/axios";

import { extendedConfigs } from "@/utils/configs";

export default {
  setup() {
    const route = useRoute();
    let menuDrawer = ref(false);
    const mq = useMq();

    let User = store.getters["user/getData"]?.UserData;
    let currentTextSize = store.getters["translation/getData"]?.textSize;
    let TextSizesList = extendedConfigs.TextSizesList;
    let showModal = ref(false);

    let Links = ref([
      { name: "FP_HOME", path: "home" },
      //{ name: "FP_SEARCH", path: "search", disabled: true },
      //{ name: "FP_NEWS", path: "news", disabled: true }, // hide for now
      {
        name: "FP_SERVICES",
        path: "services",
        children: [
          { name: "FP_ASSESSMENT", path: "services?type=assessment" },
          { name: "FP_VERIFICATION", path: "services?type=verification" },
        ],
      },
      { name: "FP_DISCLO_PLATFORM", path: "disclosure" },
      //{ name: "FP_RESOURCES", path: "resources", disabled: true }, // hide for now
      //{ name: "FP_SIGN_IN", path: "login", isAuth: true }, // moved to top header
      //{ name: "FP_REGISTER", path: "register", isAuth: true }, // hide because of top header
    ]);

    if (User) {
      Links.value = _.filter(Links.value, (r) => !r.isAuth);
      Links.value = [...Links.value, { name: "TTL_MY_ACCOUNT", path: "dashboard" }];
    }

    let FooterLinks = ref([
      { name: "FP_FAQ", path: "faq" },
      { name: "FP_PRIVACY_POLICY", path: "privacy" },
      { name: "FP_TERMS_CONDI", path: "terms" },
    ]);

    let FooterLinksContact = ref([
      {
        name: "FP_CONTACT_US",
        path: "contact",
        contactInfo: {
          MobileNumber: "",
          Email: "",
          Address: "",
        },
      },
    ]);

    let qStr = new URLSearchParams(route.query);
    qStr = qStr.toString() ? "?" + qStr.toString() : "";
    let activeMenu = ref(route.name + qStr || "home");

    let formData = reactive({
      Email: null,
    });

    let showParent = (row) => {
      if (row.children && row.children.length && row.path) {
        router.changeRoute("/" + row.path);
      }
    };

    let loadData = async () => {
      let footer = await get("/cms/get/footer.json", {}, true);
      let home = await get("/cms/get/home.json", {}, true);
      await store.dispatch("page/setData", { WebBackground: home?.WebBackground });

      FooterLinksContact.value[0].contactInfo.MobileNumber = footer?.MobileNumber;
      FooterLinksContact.value[0].contactInfo.Email = footer?.Email;
      FooterLinksContact.value[0].contactInfo.Address = footer?.Address;
    };

    onBeforeMount(async () => {
      let theme = store.getters["translation/getData"]?.textSize;
      import(`../assets/scss/${theme}.css`);
    });
    onMounted(async () => {
      await loadData();
    });

    onUpdated(() => {
      let qStr = new URLSearchParams(route.query);
      qStr = qStr.toString() ? "?" + qStr.toString() : "";
      activeMenu.value = route.name + qStr || "home";
    });

    const LangList = computed(() => {
      let list = extendedConfigs.langList;
      let cc = translation.getLanguage();
      return _.filter(list, (it) => it.code != cc);
    });

    const handleSelect = (key, keyPath) => {
      let query = {};
      let keys = key.split("?")[1];
      if (keys) {
        const urlParams = new URLSearchParams(keys);
        query = Object.fromEntries(urlParams);
      }
      router.changeRoute("/" + key, query);
    };

    const changeLang = async (val) => {
      await store.dispatch("translation/setData", { languageCode: val });
      let routes = ["/faq", "/home", "/privacy", "/terms", "/contact"]; //modx CMS pages

      if (routes.indexOf(route.path) > -1) {
        router.reload();
      } else {
        await loadData();
      }
    };

    const changeTextSize = async (val) => {
      await store.dispatch("translation/setData", { textSize: val });
      router.reload();
    };

    const subscribeEmail = async (val) => {};

    const shareNow = async (type) => {};

    const clearUserData = async () => {
      await store.dispatch("user/resetData");
      router.reload();
    };

    return {
      showParent,
      menuDrawer,
      mq,
      Links,
      FooterLinks,
      FooterLinksContact,
      activeMenu,
      formData,
      LangList,
      currentTextSize,
      TextSizesList,
      showModal,
      handleSelect,
      changeLang,
      changeTextSize,
      subscribeEmail,
      shareNow,
      User,
      clearUserData,
    };
  },
};
</script>
<style>
.front-header-cont {
  width: 100%;
  position: absolute; /* fixed */
  z-index: 999;
}
.front-header-cont *:not(i) {
  font-family: Arial !important;
  font-weight: bold;
}
.rel-header {
  position: relative;
}
.front-header {
  background-color: #fff;
  opacity: 0.9;
}
.nav-menu-cont.el-menu {
  border-bottom: 0px solid white;
  /*flex-wrap: wrap;*/
}
.nav-menu-cont.el-menu .el-submenu .el-submenu__title span {
  vertical-align: top;
}
.nav-menu-cont.el-menu .el-submenu .el-submenu__icon-arrow {
  margin-top: -5px;
}

.nav-menu-cont.el-menu .el-menu-item,
.nav-menu-cont.el-menu .el-submenu .el-submenu__title {
  height: var(--height-navmenu);
  line-height: unset;
  padding: 4px 18px 10px 18px;
  font-size: var(--f-size-h3);
  color: #676767;
}
.cus-menu-item {
  padding: 8px 0px;
  color: #6e6e6e;
  font-size: var(--f-size-h3) !important;
}
.cus-menu-item.is-active {
  color: #000;
}
.cus-contact-item {
  padding-bottom: 3px;
}
.cus-contact-item *,
.cus-contact-item p {
  color: #6e6e6e;
  font-size: var(--f-size-h4);
  line-height: var(--l-height-fr-p);
}
.nav-menu-cont.el-menu,
.nav-menu-cont.el-menu .el-menu-item,
.nav-menu-cont.el-menu .el-menu-item:not(.is-disabled):hover,
.nav-menu-cont.el-menu .el-menu-item:not(.is-disabled):focus,
.nav-menu-cont.el-menu .el-submenu .el-submenu__title:hover,
.nav-menu-cont.el-menu .el-submenu .el-submenu__title:focus {
  background-color: transparent;
}
.focus-none.el-menu-item:not(.is-disabled):hover,
.focus-none.el-menu-item:not(.is-disabled):focus {
  color: gray; /* default color */
}
.nav-menu-cont.el-menu .el-menu-item.is-active,
.nav-menu-cont.el-menu .el-submenu.is-active .el-submenu__title {
  border-bottom: 3.5px solid #1673ff;
  color: #000;
}
.f-footer-title {
  font-size: var(--f-size-footer-ttl) !important;
}
.contact-img {
  height: var(--h-contact-img);
}
.share-img {
  height: 30px;
}

.fr-top-header {
  background-color: rgba(246, 133, 90);
  padding-top: 2px;
  padding-bottom: 2px;
}
.fr-top-header *:not(i),
.focal-div .focal-content {
  font-size: var(--f-size-top-header);
  font-family: Arial !important;
  font-weight: normal;
  color: #333;
}
.focal-div .focal-content {
  line-height: var(--l-height-focal-content);
}
.fr-sm-menu-arrow {
  font-size: var(--f-size-fr-arrow) !important;
}
.focal-div .focal-title {
  font-size: var(--f-size-h3);
  margin-top: var(--mt-focal-ttl);
  color: #333;
}
.text-size-cont .small {
  height: 12px;
  width: 12px;
  margin-bottom: 1px;
}
.text-size-cont .medium {
  height: 14px;
  width: 14px;
  margin-bottom: 1px;
}
.text-size-cont .big {
  height: 16px;
  width: 16px;
  margin-bottom: 1px;
}
.head-logo {
  height: var(--h-head-logo);
}
.md-pop-submenu {
  font-size: var(--f-size-h4);
  align-items: center;
  justify-content: center;
  display: flex;
}
.front-popper .el-menu--horizontal .el-menu .el-menu-item,
.front-popper .el-menu--horizontal .el-menu .el-menu-item div {
  height: var(--h-sub-item);
}

@media (max-width: 1200px) {
  .nav-menu-cont.el-menu .el-menu-item,
  .nav-menu-cont.el-menu .el-submenu .el-submenu__title {
    padding: 3px 15px 8px 15px;
  }
}
@media (max-width: 1024px) {
  .nav-menu-cont.el-menu .el-menu-item,
  .nav-menu-cont.el-menu .el-submenu .el-submenu__title {
    height: var(--height-navmenu);
    line-height: var(--l-height-navmenu);
    padding: 3px 10px 8px 10px;
    text-align: center !important;
    display: flex;
    align-items: center;
  }
  .nav-menu-cont.el-menu .el-menu-item {
    white-space: pre-line;
  }
}
@media (max-width: 900px) {
  .nav-menu-cont.el-menu .el-menu-item,
  .nav-menu-cont.el-menu .el-submenu .el-submenu__title {
    padding: 2px 9px 8px 9px;
  }
}
@media (min-width: 768px) and (max-width: 820px) {
  .front-header,
  .fr-top-header {
    padding-right: 5% !important;
    padding-left: 5% !important;
  }
}

@media (max-width: 767px) {
  .front-header {
    background-color: transparent;
  }
  .head-logo {
    height: var(--h-head-logo);
  }
  .abs-sm-header {
    position: fixed;
  }
  .icon-cont {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /*.sm-front-menu.el-popper {
      max-width: 60%;
    }
    .sm-front-menu.el-popper .el-dropdown__list {
      padding-inline-start: 0px;
      margin-bottom: 0px;
    }
    .sm-front-menu.el-popper .el-dropdown-menu__item {
      font-family: Arial !important;
      font-weight: bold;
    }
    .sm-front-menu.el-popper .el-dropdown-menu__item:not(.is-disabled) {
      color: #6E6E6E;
    }
    .sm-front-menu.el-popper .el-dropdown-menu__item:not(.is-disabled):focus, 
    .sm-front-menu.el-popper .el-dropdown-menu__item:not(.is-disabled):hover, 
    .sm-front-menu.el-popper .el-dropdown-menu__item.is-active {
      color: #000;
      background-color: rgba(112,112,112,0.05);
    }*/
  .sm-menu-drawer {
    width: unset !important;
    background-color: rgb(251, 251, 251);
  }
  .sm-menu-drawer .el-drawer__header {
    margin-bottom: 0px;
  }
  .sm-menu-cont.el-menu {
    margin: 0px 18px;
    border-right: 0px solid #fff;
  }
  .sm-menu-cont.el-menu .el-submenu .el-menu-item {
    padding-left: 22px !important;
    padding-right: 10px;
    min-width: unset;
  }
  .sm-menu-cont.el-menu .el-menu-item,
  .sm-menu-cont.el-menu .el-submenu .el-submenu__title {
    font-family: Arial !important;
    font-weight: bold;
    height: unset;
    line-height: unset;
    padding: 6px 20px;
    font-size: var(--f-size-sm-menu-item);
    color: #6e6e6e;
  }
  .font-size-text {
    font-size: var(--f-size-sm-menu-item);
    color: #676767;
    font-weight: normal;
  }
  .text-size-cont .small {
    height: 0.8rem;
    width: 0.8rem;
    margin-bottom: var(--font-text-mb);
  }
  .text-size-cont .medium {
    height: 0.9rem;
    width: 0.9rem;
    margin-bottom: var(--font-text-mb);
  }
  .text-size-cont .big {
    height: 1rem;
    width: 1rem;
    margin-bottom: var(--font-text-mb);
  }
  .sm-menu-cont.el-menu,
  .sm-menu-cont.el-menu .el-menu-item,
  .sm-menu-cont.el-menu .el-menu-item:not(.is-disabled):hover,
  .sm-menu-cont.el-menu .el-menu-item:not(.is-disabled):focus,
  .sm-menu-cont.el-menu .el-submenu .el-submenu__title:hover,
  .sm-menu-cont.el-menu .el-submenu .el-submenu__title:focus {
    background-color: rgb(251, 251, 251);
    border-left: 3.5px solid transparent;
  }
  .sm-menu-cont.el-menu .el-submenu .el-submenu__title {
    border-left: 3.5px solid transparent;
  }
  .sm-menu-cont.el-menu .el-submenu .el-submenu__title .is-active {
    color: #000;
  }
  .sm-menu-cont.el-menu .el-menu-item.is-active {
    border-left: 3.5px solid #1673ff !important;
    color: #000;
    background-color: #fff !important;
  }

  .f-footer-title {
    font-size: var(--f-size-footer-ttl) !important;
    color: #fff;
  }
  .subscribe-card * {
    color: #fff;
  }
  .front-cont .subscribe-card .black-input .el-input__inner {
    color: #fff;
    border-bottom: 1px solid #fff;
  }
  .subscribe-cont {
    background-color: #f1832d;
  }
  .subscribe-card .f-text-orange {
    color: #fff;
    font-size: var(--f-size-footer-subttl) !important;
  }
  .f-footer-sm-submit {
    min-width: 85px;
    padding: 0px 15px;
  }
  .f-footer-sm-submit,
  .f-footer-sm-submit:hover,
  .f-footer-sm-submit:focus {
    border-color: #fff;
  }
  .footer-menu-cont.el-menu {
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .footer-menu-cont.el-menu .el-menu-item,
  .footer-menu-cont.el-menu .el-submenu .el-submenu__title {
    margin-bottom: 6px;
    padding: 3px 1px 0px 1px;
    font-size: var(--f-size-sm-footer-menu);
    line-height: var(--l-height-sm-footer-menu);
    /*flex: 1;*/
    height: 30px;
    margin-right: 20px;
  }
  .footer-menu-cont.el-menu .el-menu-item.is-active,
  .footer-menu-cont.el-menu .el-submenu.is-active .el-submenu__title {
    border-bottom: 3px solid transparent !important;
  }
  .share-img {
    height: 22px;
  }
  .contact-img {
    height: var(--h-contact-img);
  }
  .cus-contact-item *,
  .cus-contact-item p {
    font-size: var(--f-size-contact-line);
    line-height: var(--l-height-contact-line);
  }
}
</style>
